import { IconButton } from '@chakra-ui/react';

import { Icon } from '$/components/common/Icon';

export const CookiebotConsentTriggerButton = () => {
  const onTriggerContent = () => {
    if (window?.Cookiebot != null) {
      window.Cookiebot.renew();
    }
  };

  return (
    <IconButton
      pos='absolute'
      bottom='8'
      left='8'
      borderRadius='full'
      aria-label='Cookiebot'
      icon={<Icon icon='cookiebot' />}
      onClick={onTriggerContent}
    />
  );
};
